@import "config";

.downloadAppModal {
  > div {
    width: 22em !important;
  }
  .text {
    margin-bottom: $contentDefaultIndent * 2;
    font-size: 1rem;
    text-align: center;
  }
  .image {
    flex: auto;
    img {
      display: block;
      width: 100%;
      max-width: 15em;
      margin: auto;
    }
  }
}
