@import "nlib/config";
@import "animations";

.onboarding {
  display: flex;
  flex: auto;
  width: 100%;
  margin: auto;
  padding: $contentDefaultIndent * 2;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto !important;
    margin-bottom: 0;
    font-size: 1.5rem;
    svg {
      margin-bottom: $contentDefaultIndent;
      animation: rotate 2s linear infinite;
      color: $uiPrimaryColor;
      font-size: 4rem;
    }
  }
  .errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 24rem;
    margin: auto !important;
    .icon {
      opacity: 0.3;
      font-size: 8rem;
    }
    .title {
      font-size: 1.5rem;
      word-break: break-word;
    }
    .description {
      margin-top: 0.5rem;
      padding: 0 0.5rem;
      color: $uiMediumDarkColor;
      font-size: 0.9rem;
    }
  }
}
