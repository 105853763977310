@import "nlib/config";

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: $uiBackgroundColor;
  .pageContent {
    overflow: auto;
  }
  .topOffCanvas {
    position: fixed;
    z-index: 10000;
    top: -100vh;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $uiBackgroundColor;
  }
}

svg {
  min-width: 1em;
  font-size: 1rem;
}

@media (max-width: $maxMobileContainerWidth) {
  .app {
    box-shadow: none;
  }
}
