@import "nlib/config";
@import "animations";

.navbar {
  position: fixed;
  z-index: 4;
  bottom: 0;
  width: 100%;
  height: $mobileNavBarHeight;
  background-color: $uiWhiteColor;
  box-shadow: 0 -2px 10px rgba($uiFontColor, 0.05);
  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 32rem;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .navItem {
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: $contentDefaultIndent / 2 $contentDefaultIndent / 2 0;
    padding-bottom: 1rem;
    overflow: hidden;
    color: $uiMediumDarkColor;
    line-height: 1;
    text-decoration: none;
    .icon {
      display: flex;
      position: relative;
      svg {
        font-size: 1.5rem;
      }
      .notification {
        position: absolute;
        top: -0.375rem;
        right: -0.375rem;
        justify-content: center;
        margin-left: $contentDefaultIndent;
        background-color: $uiNegativeColor;
        color: $uiWhiteColor;
        &.animated {
          animation: blink 1s ease-out infinite;
        }
      }
    }
    .title {
      width: 100%;
      margin-top: 0.125rem;
      overflow: hidden;
      line-height: 1.5;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.active {
      color: $uiHighlightColor;
    }
    &.uploading {
      .icon {
        .notification {
          animation: blink 1s ease-out infinite;
          background-color: $uiWarningColor;
        }
      }
    }
    &.danger {
      .icon {
        color: $uiNegativeColor;
      }
    }
  }
  &.czCountry {
    .navItem {
      flex: 1;
    }
  }
}

@media (max-width: 420px) {
  .navbar {
    &.fullWidth {
      .navItem {
        .title {
          font-size: 0.75rem;
        }
      }
    }
  }
}
