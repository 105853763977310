@import "nlib/config";

.header {
  position: relative;
  z-index: 5;
  background-color: $uiWhiteColor;
  &.superAdminAccess {
    background-image: linear-gradient(225deg, $uiWarningColor 1em, $uiWhiteColor 0);
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 4rem;
  }
  .logo {
    display: flex;
    align-items: center;
    min-width: fit-content;
    margin-right: $contentDefaultIndent * 2;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    img {
      visibility: hidden;
      max-width: 3.25rem;
      height: 100%;
      max-height: 2rem;
      opacity: 0;
    }
  }
  .businessSelectorWrap {
    position: relative;
    flex: 1;
    height: 2.5rem;
    .businessSelector {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 20rem;
      button {
        height: 2.5rem !important;
      }
    }
  }
  .aside {
    display: flex;
    align-items: center;
    margin-left: $contentDefaultIndent * 2;
    .item + .item {
      margin-left: $contentDefaultIndent;
    }
  }
}
