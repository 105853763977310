@import "nlib/config";

.noBusinessData {
  display: flex;
  flex: auto;
  width: 100%;
  margin: auto;
  padding: $contentDefaultIndent * 2;
  text-align: center;
  .postSignUp {
    margin: auto;
  }
  .errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 24rem;
    margin: auto !important;
    .icon {
      opacity: 0.3;
      font-size: 8rem;
    }
    .title {
      font-size: 1.5rem;
      word-break: break-word;
    }
    .description {
      margin-top: 0.5rem;
      padding: 0 0.5rem;
      color: $uiMediumDarkColor;
      font-size: 0.9rem;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto !important;
    margin-bottom: 0;
    font-size: 1.25rem;
    svg {
      margin-bottom: $contentDefaultIndent;
      color: lighten($uiDarkColor, 45%);
      font-size: 4rem;
    }
    small {
      font-size: 1.125rem;
    }
  }
}

@media (max-width: 512px) {
  .noBusinessData {
    .errorContainer {
      .title {
        font-size: 1.25rem;
      }
    }
  }
}
